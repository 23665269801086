<template>
  <div class="tab-ls-wrap">
    <div
      v-for="ele in tabs"
      :key="ele.name"
      @click="!$route.fullPath.includes(ele.linkTo) && $router.push(ele.linkTo)"
      :class="['tab-item', { active: $route.fullPath.includes(ele.linkTo) }]"
    >
      {{ ele.name }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'container-tab',
  data() {
    return {
      tabs: [
        {
          name: '园区概况',
          linkTo: '/zhanyu/overview',
        },
        {
          name: '养殖与苗种',
          linkTo: '/zhanyu/breedingSeeding',
        },
        {
          name: '饲料与加工',
          linkTo: '/zhanyu/feedProcessing',
        },
        {
          name: '物流贸易',
          linkTo: '/zhanyu/logisticsTrade',
        },
        {
          name: '数字园区',
          linkTo: '/zhanyu/digitalPark',
        },
        {
          name: '湛渔品牌',
          linkTo: '/zhanyu/zyuBrand',
        },
      ],
    }
  },
  computed: {},
  methods: {},
  components: {},
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
.tab-ls-wrap {
  height: 0.54rem;
  margin: 0.22rem auto;
  display: flex;
  justify-content: center;
  align-content: center;
  .tab-item {

    width: 1.64rem;
    height: 0.54rem;
    margin-left: 0.1rem;
    background: url(../assets/img/large-screen/bg-tab-default.png) no-repeat
    100% 100%;
      background-size: 100% 100%;
    color: #d1e2f4;
    font-family: 'Microsoft YaHei';
    font-size: 0.24rem;
    line-height: 0.54rem;
    cursor: pointer;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }

    &:hover,
    &.active {
      background: url(../assets/img/large-screen/bg-tab-active.png) no-repeat
      100% 100%;
      background-size: 100% 100%;
      color: #2aedff;
      font-weight: bold;
    }
  }
}

</style>
